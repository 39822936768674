<template>
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="*Avatar:"
            label-cols-md="12"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style="max-width: 200px;"
            >
              <b-aspect aspect="1:1">
                <b-img-lazy
                  :src="model.avatar"
                  fluid
                  thumbnail
                  rounded
                  style="position: absolute; max-width: 200px;"
                />
              </b-aspect>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Title:"
                label-cols-md="12"
              >
                <div class="d-flex align-items-center h-100">
                  {{ model.title }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('Position')+`:`"
                label-cols-md="12"
              >
                <div class="d-flex align-items-center h-100">
                  {{ model.position }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Is active:"
                label-cols-md="12"
              >
                <ActiveStatus :value="model.is_active" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('Content') + `:`"
                label-cols-md="12"
              >
                <div class="d-flex align-items-center h-100">
                  {{ model.content }}
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Created at:"
                label-cols-md="12"
              >
                <div class="d-flex align-items-center h-100">
                  {{ model.created_at }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BImgLazy,
    BAspect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      model: {},
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/faq/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
